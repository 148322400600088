import React from 'react';

const WhiteBgLogo = () => {
    return (
        <svg width="60px" height="60px" viewBox="0 0 843 843" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1791_954)">
<path d="M843 0H0V843H843V0Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M402.335 619H225.042L225 306.265H307.234L307.78 544.813L397.826 543.846C476.176 543.846 527.47 496.946 527.47 421.226C527.47 345.508 475.049 305.859 396.698 305.859L307.234 306.265V224H354.566H402.376C531.456 224 618 301.736 618 421.53C618 464.701 606.755 502.402 586.328 532.97H532.033L531.941 585.3C497.05 607.052 452.961 619 402.335 619Z" fill="#333333"/>
<path d="M618 619V543H542V619H618Z" fill="#C10206"/>
</g>
<defs>
<clipPath id="clip0_1791_954">
<rect width="843" height="843" fill="white"/>
</clipPath>
</defs>
</svg>

    );
};

export default WhiteBgLogo;